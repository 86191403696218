import React from 'react';
import Layout from '../components/layout/layout';
import * as styles from '../components/blog.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Commentsimg from '../images/comments.svg';
import ProfileImg from '../images/profile.svg';
import BlogImg from '../images/blogImg.png';
import Facebook from '../images/facebookBlue.png';
import Twitter from '../images/twitterBlue.png';
import Linkedin from '../images/linkedinBlue.png';
import MailBlue from '../images/mailBlue.png';
import { FacebookProvider, Comments } from 'react-facebook';
import FeaturedPosts from '../components/featuredPosts';
import CardsRowDivImg3 from '../images/cardsRowDiv3.png';

class BradenWinstheSnowball extends React.Component{
  constructor(props){
    super(props);
    this.state={

    }
  }
  
  render(){
    return(
      <Layout>
        <div id="fb-root"></div>
          <div className={styles.landingSection}>
            <div className={styles.landingSectionSubDiv}>
              <text className={styles.blogHeader}>‘Fortnite’ Fever Gaining Foothold in the Ohio Valley</text>
              <div className={styles.subtitle}>
                <div className={styles.profileCol2}>
                  <img className={styles.ProfileImg} src={ProfileImg} alt="landingimg" />
                  <text className={styles.profileTest}>By Alan Olson</text>
                  <p>27 Sep, 2018</p>
                </div>
                <div className={styles.profileCol2} id={styles.profilecolId}>
                  <img className={styles.ProfileImg} src={Commentsimg} alt="landingimg" />
                  <text className={styles.profileTest}>0 Comments</text>
                </div>
              </div>
              <img className={styles.blogImg} src={CardsRowDivImg3} alt="landingimg" />
              <div className={styles.imgText}>
                <text className={styles.blogDesc}>Photo by Alan Olson Players watch, critique and jeer Wednesday evening during warmups at the Fortnite tournament at Jerry’s Bar in McMechen. From left are Justin Walker, Will Dove, Harrison Nick, Chase Walton and Jason Custer.
                </text>
              </div>
              <text className={styles.blogDesc}>
                <p>McMECHEN — While some at sports bars Wednesday evening across the Ohio Valley watched the Pirates play the Cubs, Jerry’s Bar was playing host to the local e-sports scene with a Fortnite: Battle Royale tournament.</p>

                <p>Four men brought their game consoles to the bar to play a double-elimination tournament of the third-person shooter, while Will Dove, of ProGame Sports — the event’s sponsor and partner — was on hand to stream the event online.</p>

                <p>Dove, who acted as the host of the event, invited four of his friends to compete to test the waters for how the event would be received. He said he hopes more events such as the one held Wednesday will take place in the future.</p>

                <p>“We’re going to be hosting, probably, weekly tournaments, whether it be Madden, Fortnite, any of those types of games,” Dove said. “It’s going to be a big thing come game time, when this does blow up. This is a big thing in sports, and being able to invest in this is going to be crazy.”</p>

                <p>While some select bars across the country have looked at broadcasting e-sports in the past — with Starcraft, League of Legends, and Dota 2 being uncommon sights around their respective world tournaments — bar owner Jake Padlow said he sees the games as an untapped market compared to the established giants of football, baseball and other common spectator sports.</p>

                <p>“The typical sports — it’s boring,” Padlow said. “It’s repetitive, it’s the same thing. You don’t bring a new audience. If you brought e-sports to the track, it’d blow up.”</p>

                <p>Padlow also said the bar’s normal clientele, who skewed toward being older, were initially unaware of the game’s popularity. But he said he’s starting to see potential for a younger crowd to be drawn in.</p>

                <p>Dove said that, down the line, the bar would be looking to bring in other games. Those could include Call of Duty, League of Legends and Runescape, to name a few.</p>

                <p>While there was no prize money involved, Padlow said ProGame Sports was paying the entrants as compensation for helping test the waters. ProGame’s phone app also was being tested. The app facilitates betting on the outcome of events.</p>

                <p>Just before the first game of the tournament started, player Jason Custer said he was thrilled to be in the initial stages of a growing field.</p>

                <p>“It’s pretty awesome,” Custer said. “This is an expanding field, that’s really got a chance to be popular. It’s a really great experience.”</p>

                <p>Fortnite: Battle Royale, a multiplayer game, has been the top-grossing console game in 2018 and has placing seventh in computer-game sales. At the recently-opened Halloween stores opening their doors across the valley, Fortnite licensed and inspired merchandise to stock the shelves. Crackshot and Skull Trooper costumes with their oversized, cartoonish weapons, sit right next to classic favorites such as the Joker and Harley Quinn merchandise.</p>

                <p>The games can be followed on Twitter @Pro–game–sports, or on Dove’s stream at twitch.tv/DrDoveMan.</p>

                <p>Source:  <a href="http://www.theintelligencer.net/news/top-headlines/2018/09/fortnite-fever-gaining-foothold-in-the-ohio-valley/">http://www.theintelligencer.net/news/top-headlines/2018/09/fortnite-fever-gaining-foothold-in-the-oh...</a></p>
              </text>
              <div className={styles.oldPostsLink}>
              <a className={styles.servicelinks} href="/jeffrey-v-kessler-joins-progame-sports"><text>{'<'}Older Post</text></a>
              <a className={styles.servicelinks} href="/blockchain"><text>Newer Post{'>'}</text></a>
              </div>
              <div className={styles.BlogsocialLinksDiv}>
                <img className={styles.social} src={Facebook} alt="landingimg" />
                <img className={styles.social} src={Twitter} alt="landingimg" />
                <img className={styles.social} src={Linkedin} alt="landingimg" />
                <img className={styles.social} src={MailBlue} alt="landingimg" />
              </div>
              <iframe name="f297624ad60196" width="500px" height="100px" data-testid="fb:comments Facebook Social Plugin" title="fb:comments Facebook Social Plugin" frameborder="0" allowtransparency="true" allowfullscreen="true" scrolling="no" allow="encrypted-media" src="https://www.facebook.com/v2.0/plugins/comments.php?app_id=691895914788460&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df247eedd96682d4%26domain%3Dfriendly-curran-11b640.netlify.app%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Ffriendly-curran-11b640.netlify.app%252Ffe72a2fa7cef6c%26relation%3Dparent.parent&amp;color_scheme=light&amp;container_width=714&amp;height=100&amp;href=https%3A%2F%2Fwww.facebook.com%2Fblak%2F&amp;locale=en_US&amp;numposts=6&amp;sdk=joey&amp;version=v2.0&amp;width=500" style={{border: 'none', visibility: 'visible', width: '500px', height: '458px'}} class=""></iframe>
              <div className={styles.featuredNewsDiv}>
                <text>Featured News</text>
              </div>
              </div>
            </div>
          <div>
          <FeaturedPosts/>
        </div>
      </Layout>
    )
  }
}

export default BradenWinstheSnowball